.wildcardSearch{
    
    h4 {
        font-size: 0.9rem;
        padding: 0.3rem;
        color:#fff;
        background-color: #194342;
        border-radius:3px;
        margin-bottom: 0.5rem;
    }

    .form-group {
        margin-bottom: 0;
    }

    .form-row > .col, .form-row > [class*="col-"] {
        text-align: left;
    }
}