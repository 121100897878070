@import "~bootstrap/scss/bootstrap";

/* The following line can be included in your src/index.js or App.js file */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-primary{
  background-color: #194445;
  border: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  }
  
::-webkit-scrollbar-track {
  width: 2px;
  border: 1px dashed #F08721;
  }
  
::-webkit-scrollbar-thumb {
  background: #F08721;
  }
  
::-webkit-scrollbar-thumb:hover {
  background: #b55c05;  
  }

.modal-header{
  background: gold;
  .modal-title {
    line-height: unset;
  }
}

.react-bs-table-sizePerPage-dropdown{
  .dropdown-menu {
    bottom: 100%;
    top: unset !important
  }
}

tbody{
  .link {
    cursor: pointer;

    &:hover{
      color: #157ad8
    }
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}