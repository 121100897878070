.legal-banner{
  background-color: beige;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  padding-left: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;

  .badge-success {
    color: #fff;
    background-color: #28a745;
    font-size: 1.2rem;
}
}