.manageUser {
    text-align: left;
    height: 100%;
    padding: 1rem !important;
    height: 75%;

    .form-control {
        display: block;
        width: 100%;
        height: calc(1.1em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2;
        color: #495057;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    thead {
        tr {
            background: #184240;
            color: #fff;
        }
    }

    .header {
        font-size: 0.9rem;
        padding: 0.3rem !important;
        color:#fff !important;
        background-color: #194342;
        border-radius:3px;
        margin-bottom: 0.5rem;
        font-weight: 500;

        &.main {

        }

        &.others {
            margin-top: 0.5rem;
        }

        &.document {
            margin-top: 0.5rem;

            .value {
                cursor: pointer;
            }
        }
    }
    .fieldItem {
        margin-bottom: 0.3rem;
        font-size: 0.87rem;
    }

    .value {
        font-weight: 500;
        font-size: 0.97rem;

        &.name {
            text-transform: uppercase;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &.notes {
            min-height:50px;
        }
    }
}