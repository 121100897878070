.actionBtn{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    justify-items: center;

    .button {
        margin: 5px 0;
        margin-right: 5px;
        border: none;
        padding: 2px 10px;
        font-size: 0.9rem;
        background-color: burlywood;
        border-radius: 3px;
        cursor: pointer;

        &:hover{
            background-color: rgb(231, 174, 100);
        }
        &:nth-child(2){
            background-color: red;
            color:#fff;
            font-weight: 500;

            &:hover{
                background-color: rgb(230, 49, 49);
            }
        }

    }
}