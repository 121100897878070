.mainSettings{
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    
    small {
        color: #888;
        font-size: 0.86rem;
    }

    .dashTitle{
        text-align: left;
        width: 100%;
        padding: 0.6rem;
        padding-bottom: 0;
        color: #1a4543;
        font-size: 1.0rem;
        text-decoration: underline;
    }

    main {
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;  
        background-color: #eee;
        justify-content: flex-start !important;

        .logTableTitle{
            font-size: 1.2rem;
        }
        thead {
            background-color: #fff;
        }

        td {
            font-size: 0.8rem;
        }
        tbody {
            tr {
                cursor: pointer;

                &:hover {
                    background-color: #dedede;
                }
            }
        }

        .modalTitle {
            text-transform: capitalize !important;
        }

        .noResults {
            color: red;
        }

    }
}
