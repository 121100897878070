.checkoutModal {
    text-align: left;

    small.required {
        color: red
    }
    .header {
        font-size: 0.9rem;
        padding: 0.3rem !important;
        color:#fff !important;
        background-color: #194342;
        border-radius:3px;
        margin-bottom: 0.5rem;
        font-weight: 500;

        &.main {

        }

        &.others {
            margin-top: 0.5rem;
        }

        &.document {
            margin-top: 0.5rem;

            .value {
                cursor: pointer;
            }
        }
    }
    .fieldItem {
        margin-bottom: 0.3rem;
        font-size: 0.87rem;
    }

    .value {
        font-weight: 500;
        font-size: 0.97rem;

        &.name {
            text-transform: uppercase;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &.notes {
            min-height:50px;
        }
    }
}