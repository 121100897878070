.usersPage {
    // background-color: #f8f9fa;
    background-image: url('../../assets/img/bg.jpg') no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;  
    flex-direction: row;
    padding: 0px;
    z-index: 1; 

    .dashboard {
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        .sideBar {
            width: 100%;
            min-height: 50%;
            border-right: 1px solid #ddd;
            background-color: #e9ecef;
            overflow-y: auto;
            height: 100%;

            a {
                text-decoration: none;
                color: #fff;
            }

            .menu-item {
                &:hover {
                    box-shadow: 0 0 10px rgba(0,0,0,.3);
                }
            }

            header {
                padding: 1.4rem 0.6rem;
                h4 {
                    font-size: 2rem;
                    color: #163f41;
                }
            }

            .actionBtn{
                margin-top:2.0rem;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                width: 92%;
                .btn {
                    width: 6.3rem;
                }
            }
        }
        .mainSection {
            background-color: #e9ecef;
            background-color: inherit;
            border-radius: 4px;
            padding: 0;

            header {
                background-color: #fff;
                background-color: inherit;
                padding: 1.0rem 0.6rem;
                padding-top: 0.4rem;
                // border-bottom: 1px solid #eee;

                h4 {
                    font-weight: 500;
                    margin-top:0.2rem;
                    color: #ddd;
                }
            }

            main {
                overflow: hidden;
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;  
                background-color: #eee;

                .container {
                    width: 100%;
                    padding-right: 0;
                    padding-left: 0;
                    margin-right: auto;
                    margin-left: auto;
                    overflow-y: scroll;
                }

                .columns.is-gapless:last-child {
                    position: fixed !important;
                    bottom: 0;
                    width: 100%;
                }

                .has-margin-bottom-15 {
                    margin-bottom: 0px!important;
                }

                .box {
                    background-color: white;
                    border-radius: 2px;
                    padding: 0.35rem;
                }

                .pdfViewer {
                    overflow-y: scroll;
                    width: 100%;
                    height: 100%;

                    .columns.is-gapless:last-child {
                        position: fixed !important;
                        bottom: 0;
                        width: 100%;
                    }

                    .has-margin-bottom-15 {
                        margin-bottom: 0px!important;
                    }

                    .box {
                        background-color: white;
                        border-radius: 2px;
                        padding: 0.35rem;
                    }
                }
            }
        }
    }


    

}