.searchPage {
    background-color: #f8f9fa;
    background-image: url('../../assets/img/bg.jpg');
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;  
    flex-direction: row;
    padding: 0px;
    z-index: 1; 
    .search {
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        .sideBar {
            width: 100%;
            min-height: 20vh;
            border-right: 1px solid #ddd;
            background-color: #e9ecef;

            header {
                padding: 0.3rem 0.6rem;
                h4 {
                    font-size: 2rem;
                    color: #184244;
                }
                margin-top: 2.0rem;
                margin-bottom: 1.5rem; 
            }

            .actionBtn{
                margin-top: 2.0rem;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                
                .btn {
                    width: 6rem;
                }
            }
        }
        .mainSection {
            padding: 0;

            header {
                background-color: #fff;
                background-color: inherit;
                padding: 1.0rem 0.6rem;
                padding-top: 0.4rem;
                // border-bottom: 1px solid #eee;

                h4 {
                    font-weight: 500;
                    margin-top:0.2rem;
                    color: #ddd;
                }
            }

            main {
                overflow: hidden;
                overflow-y: auto;
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;  
                background-color: #eee;


                thead {
                    background-color: #fff;
                }

                td {
                    font-size: 0.8rem;
                }
                tbody {
                    tr {
                        cursor: pointer;
    
                        &:hover {
                            background-color: #dedede;
                        }
                    }
                }

                .modalTitle {
                    text-transform: capitalize !important;
                }

                .noResults {
                    color: red;
                }

            }
        }
    }

}