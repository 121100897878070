.mainReport{
    padding: 0;
    display: flex;
    flex-direction: column;

    .statSection {
        background-color: #fff;
        // height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .dashTitle{
            text-align: left;
            width: 100%;
            padding: 1.1rem;
            padding-bottom: 0;
            color: #1a4543;
            font-size: 1.2rem;
        }
    }
    header {
        background-color: #fff;
        background-color: inherit;
        padding: 1.0rem 0.6rem;
        padding-top: 0.4rem;
        // border-bottom: 1px solid #eee;

        h4 {
            font-weight: 500;
            margin-top:0.2rem;
            color: #ddd;
        }
    }

    main {
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;  
        background-color: #eee;

        .logTableTitle{
            font-size: 1.2rem;
        }
        thead {
            background-color: #fff;
        }

        td {
            font-size: 0.8rem;
        }
        tbody {
            tr {
                cursor: pointer;

                &:hover {
                    background-color: #dedede;
                }
            }
        }

        .modalTitle {
            text-transform: capitalize !important;
        }

        .noResults {
            color: red;
        }

    }
}
