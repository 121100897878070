*{
    box-sizing:border-box;
}

/* Customize Colors */
$primary:   #295e87;

html {
    overflow-x: hidden;
    overflow-y: hidden !important;
}

header {
    img {
        height: auto;
        max-width: 5%;
    }
}