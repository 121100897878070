.loginPage{
    .mainHeader {
        background-color: #194342;
        background-image: url('../../assets/img/bg.jpg');
        min-height:120px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 0;
        padding: 0.2% 0;
        padding-top: 1%;

        .mainSection{
            div img {
                max-width: 35%;
            }
            header
            {
                img {
                    height: auto;
                    max-width: 8%;
                }
                h4 {
                    color: #fff;
                    font-size: 1.7rem;
                }

                h5{
                    color: #f5f5f5;
                }
            }
        }
    }

}

.ugandaFlag{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.color {
    background-color: red;
    width:100%;
    height: 3px;
    &.red {
        background-color: red;
    }

    &.yellow{
        background-color: gold;
    }

    &.black {
        background-color: #000;
    }
}
.loginMainContent {
    /* background-color: #194342;
    background-image: url('../../assets/img/bg.jpg'); */
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center; /*centers items on the line (the x-axis by default)*/
    align-items: center; /*centers items on the cross-axis (y by default)*/

    .loginRow {
        width: 100%;
        min-height: 20vh;

        .btn-primary{
            background-color: #194445;
            border: none;
        }
    }
    .loginSection {
        padding: 40px 50px;
        background-color: #e9ecef;
        border-radius: 4px;
        background: inherit;
        position: relative;
        overflow: hidden;
        z-index: 1;

        h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
            color: #1a4644;
        }

        &::after {
            z-index: -1;
            content: '';
            position: absolute;
            top: -10px;
            bottom: -10px;
            right: -10px;
            left: -10px;
            background: inherit;
            /* box-shadow: inset 0 0 2000px rgba(47, 116, 206, 0.5);
            filter: blur(10px);*/
            box-shadow: inset 0 0 2000px rgba(25, 68, 68, 0.32);
            filter: blur(10px);
        }
    }
}