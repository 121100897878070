header.user-header  {
    padding: 0.3rem 0.6rem;
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;

    img {
        padding: 5px;
    }
    .user-label{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        font-size: 0.9rem;
        span {
            text-transform: capitalize;
            font-size: 1.1rem;
        }
    }

    .menu-section {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin: 0.5rem auto;
        margin-top: 1.5rem;

    }

    .menu-label{
        display: flex;
        align-items: center;
        justify-content: left;
        font-weight: bold;
        color: #383d41;
    }
}