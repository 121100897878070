.settingsPage {
    background-color: #f8f9fa;
    background-image: url('../../assets/img/bg.jpg');
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;  
    flex-direction: row;
    padding: 0px;
    z-index: 1; 
    .search {
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        .sideBar {
            width: 100%;
            min-height: 20vh;
            border-right: 1px solid #ddd;
            background-color: #e9ecef;

            header {
                padding: 0.3rem 0.6rem;
                h4 {
                    font-size: 2rem;
                    color: #184244;
                }
                margin-top: 2.0rem;
                margin-bottom: 1.5rem; 
            }
            .list-group-item{
                text-align: left;
            }
            .list-group-item + .list-group-item {
                margin-top: 3px;
            }
            .actionBtn{
                margin-top: 2.0rem;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                
                .btn {
                    width: 6rem;
                }
            }
        }
        .mainSection {
            border-radius: 4px;
            padding: 0;
            display: flex;
            flex-direction: column;
            background: #fff;
            overflow-y: auto;
            header {
                background-color: inherit;
                padding: 0.6rem 0.6rem;
                border-bottom: 1px solid #eee;

                // border-bottom: 1px solid #eee;

                h4 {
                    font-weight: 500;
                    margin-top:0.2rem;
                    color: #555;
                    font-size: 1.2rem;
                    text-align: left;
                }
            }

            main {
                background-color: #fff;
                // height: 250px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px
            }
        }
    }

}