.dashboardCard {
    display: flex;
    flex-direction: row;
    width: 98%;
    margin: 2% !important;
    margin-top: 0.2% !important;
    .col {
        background: linear-gradient(-70deg, #e6f7fe  40%, #ffffff 40%);
        height: 130px;
        border-radius: 0.2rem;
        box-shadow: 0px 1px 5px 1px #45414e2e;

        &:nth-of-type(n+1) {
            margin-left:0.5rem;
        }

        &:nth-of-type(2) {
            background: linear-gradient(-70deg, #f5dcc6 40%, #ffffff 40%);
        }

        &:nth-of-type(3) {
            background: linear-gradient(-70deg, #c9eee7 40%, #ffffff 40%);
        }

        .stats-area {
            position: absolute;
            bottom: 1rem;
            display: flex;
            flex-flow: column;
            text-align: left;
            strong {
                font-size: 2.0rem;
                color:#33693a;
                color: #29255c;
            }
            span{
                color:#989898;
            }
        }

        .card-icon {
            position: absolute;
            right: 1.9rem;
            bottom: 33%;
        }
    }
}