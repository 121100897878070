.dashboardPage {
    background-color: #fff;;
    background-image: url('../../assets/img/bg.jpg');
    overflow: hidden;
    position: absolute;
    width: 100%;
    min-height: 96%;
    display: flex;  
    flex-direction: row;
    padding: 0px;
    z-index: 1; 
    top: 22%;

    .dashboard {
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        .sideBar {
            width: 100%;
            min-height: 20vh;
            border-right: 1px solid #ddd;
            background-color: #e9ecef;

            header {
                padding: 0.3rem 0.6rem;
                h4 {
                    font-size: 2rem;
                    color: #184244;
                }
                margin-top: 2.0rem;
                margin-bottom: 1.5rem; 
            }

            .actionBtn{
                margin-top: 2.0rem;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                
                .btn {
                    width: 6rem;
                }
            }
        }
        .mainSection {
            padding: 0;

            header {
                background-color: #fff;
                background-color: inherit;
                padding: 1.0rem 0.6rem;
                padding-top: 0.4rem;
                // border-bottom: 1px solid #eee;

                h4 {
                    font-weight: 500;
                    margin-top:0.2rem;
                    color: #ddd;
                }
            }

            main {
                overflow: hidden;
                overflow-y: auto;
                position: absolute;
                width: 100%;
                height: 82%;
                display: flex;  
                justify-content: center;
                align-items: center;
                background-color: #ffffff;


                .menuBox{
                    min-width: 50%;
                    padding: 25px;
                    box-shadow: inset 0 0 2000px rgba(25, 68, 68, 0.32);

                    .menuBoxRow {
                        display: grid;
                        grid-template-columns: auto auto;
                        grid-template-rows: auto auto; 
                        column-gap: 10px;
                        row-gap: 15px;

                        div {
                            min-height: 100px;
                            background-color: #f4f4f4;
                            background: linear-gradient(70deg,#f0bb3066 12%,#f4f4f4 0);
                            margin:5px 0;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
                            border-radius: 2px;
                            padding: 10px;
                            svg {
                                font-size: 2.2rem;
                                color: #024242;
                            }

                            a {
                                text-decoration: none;
                                font-weight: 500;

                            }
                            &:hover{
                                box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
                                cursor: pointer;
                                background: linear-gradient(70deg,red 12%,#f4f4f4 0);
                                color: #fff;
                                transition: 1s;
                            }

                            &:nth-child(1){
                                background: linear-gradient(70deg,orange 12%,#f4f4f4 0);
                                &:hover{
                                    background: linear-gradient(70deg,red 12%,#f4f4f4 0);
                                    transition: 0.2s;
                                }
                            }
                            &:nth-child(2){
                                background: linear-gradient(70deg,lightblue 12%,#f4f4f4 0);
                                &:hover{
                                    background: linear-gradient(70deg,red 12%,#f4f4f4 0);
                                    transition: 0.2s;
                                }
                            }
                            &:nth-child(3){
                                background: linear-gradient(70deg,green 12%,#f4f4f4 0);
                                &:hover{
                                    background: linear-gradient(70deg,red 12%,#f4f4f4 0);
                                    transition: 0.2s;
                                }
                            }
                            &:nth-child(4){
                                background: linear-gradient(70deg,gray 12%,#f4f4f4 0);
                                &:hover{
                                    background: linear-gradient(70deg,red 12%,#f4f4f4 0);
                                    transition: 0.2s;
                                }
                            }                            
                        }
                    }
                }

                .modalTitle {
                    text-transform: capitalize !important;
                }

                .noResults {
                    color: red;
                }

            }
        }
    }

}